.hamburger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 3000;
  position: relative;
}

.hamburger-menu.black .line {
  background-color: black;
}

.nav-cover.active + .hamburger-menu {
  display: block;
}

.line {
  background-color: white;
  height: 3px;
  margin: 2px 0;
  width: 25px;
}

.nav-cover {
  height: 100%;
  max-width: 400px;
  min-width: 300px;
  width: 30vw;
  position: fixed;
  z-index: 1;
  right: -400px;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  font: "Roboto Condensed";

  &.active {
    right: 0px;
    visibility: visible;
  }
}

.nav-links {
  font-family: "Roboto Condensed";
  display: flex;
  text-align: right;
  left: 0;
  float: right;
  margin-right: 12%;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 0.25rem;
  padding-right: 2rem;
  font-size: 40px;
  font-family: "Roboto Condensed";
}

.nav-links a:hover {
  color: rgb(190, 0, 0);
  text-decoration: none;
  padding: 0.25rem;
  padding-right: 2rem;
}

.hamburger-menu.toggle .line:nth-child(1) {
  transform: rotate(-45deg) translateY(7px);
  transition: transform 0.3s ease-in-out;
  background-color: white;
}

.hamburger-menu.toggle .line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.toggle .line:nth-child(3) {
  transform: rotate(45deg) translateY(-7px);
  transition: transform 0.3s ease-in-out;
  background-color: white;
}

.nav-cover.active + .hamburger-menu {
  z-index: 4;
}

@media (max-width: 768px) {
  .nav-links {
    padding-bottom: 4rem;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    float: none;
    height: 100%;
    flex-direction: column;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
    padding: 0.1rem;
    padding-right: 0;
    font-size: 20px;
    font-family: "Roboto Condensed";
  }

  .nav-links a:hover {
    color: rgb(190, 0, 0);
    text-decoration: none;
    padding: 0.1rem;
    padding-right: 0rem;
  }

  .nav-cover {
    min-width: 100%;
    min-height: 60%;
    height: 30vw;
    position: fixed;
    z-index: 1;
    top: 100%;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    font: "Roboto Condensed";

    &.active {
      top: 400px;
      width: 100vw;
      visibility: visible;
    }
  }

  .hamburger-menu.toggle {
    position: fixed;
    top: 430px;
    right: 20px;
    z-index: 3001;
  }
}

@media (max-height: 700px) {
  .nav-links {
    padding-bottom: 7rem;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    float: none;
    height: 100%;
    flex-direction: column;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
    padding: 0.1rem;
    padding-right: 0;
    font-size: 15px;
    font-family: "Roboto Condensed";
  }

  .nav-links a:hover {
    text-decoration: none;
    padding: 0.1rem;
    padding-right: 0rem;
  }

  .nav-cover {
    min-width: 100%;
    min-height: 60%;
    height: 30vw;
    position: fixed;
    z-index: 1;
    top: 100%;
    right: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    &.active {
      top: 400px;
      width: 100vw;
      visibility: visible;
    }
  }

  .hamburger-menu.toggle {
    position: fixed;
    top: 430px;
    right: 20px;
    z-index: 3001;
  }
}
