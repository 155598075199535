#typewriter {
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 0.2em;
  position: relative;
  height: 1.4em; /* Adjust as needed */
  align-items: center;
  color: #af1713;
  font-family: Roboto, sans-serif;
}
#typewriter::after {
  content: "|"; /* The cursor */
  position: absolute;
  animation: blink 0.5s infinite;
  font-family: Roboto, sans-serif;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
