.progress-dial-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-dial-wrapper {
  width: 100;
  height: 100;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.progress-dial-background {
  stroke: white;
  fill: none;
}

.progress-dial-foreground {
  fill: none;
  transition: "stroke-dashoffset 0.5s ease";
}

.progress-dial-text {
  font-size: 1.5rem;
  fill: white;
}

.dial_text-label {
  font-size: 1rem;
  color: white;
}
