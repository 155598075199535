.complication__container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  padding: 0.8rem;
  border-radius: 1rem;
  background-color: #857f7f;
  cursor: pointer;

  &.medium-grid-size {
    min-width: 180px;
    min-height: 170px;
    max-height: 185px;
  }

  &.large-grid-size {
    max-width: 420px;
    min-width: 370px;
    max-height: 185px;
  }
}

@media screen and (max-width: 600px) {
  .complication__container {
    padding: 1rem;
  }
  .medium-size {
    flex: 1 1 200px;
  }
}

@media (max-width: 768px) {
  .complication__container {
    margin: 0rem 2rem;

    &.medium-grid-size {
      max-width: 350px;
      height: 200px;
    }

    &.large-grid-size {
      max-width: 350px;
      min-width: 300px;
      height: 200px;
    }
  }
}
