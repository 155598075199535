#imgLogo {
  padding-top: 10px;
  width: 200px;
  cursor: pointer;
}

#logo {
  font-size: 40px;
  font-weight: 900;
  font-style: italic;
  margin: 0px;
}

@media (max-width: 768px) {
  #imgLogo {
    width: 150px;
  }
}
