.error_log {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  padding-top: 0.5rem;
  padding-left: 0.5rem;

  .scrolling_list {
    max-height: 140px;
    font-size: 0.75rem;
    overflow-y: auto;
  }

  color: black;

  border-style: solid;
  border-width: 2px;
  border-radius: 1rem;

  .title {
    display: inline;
    text-align: center;
    vertical-align: top;
  }
}

@media (max-width: 768px) {
  .error_log {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }
}
