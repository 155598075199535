.team-member-container {
  display: grid;

  .name {
    font-size: 2rem;
    font-weight: 400;
  }

  .discipline {
    align-self: stretch;
    font-size: 1rem;
    font-weight: 400;
  }

  .component {
    font-size: 1rem;
    font-weight: 400;
  }

  .image-text-component {
    margin-top: 2rem;
    display: flex;
    color: rgb(255, 255, 255);
    width: 50%;

    &.right {
      justify-self: right;
      justify-content: right;
      background-color: rgba(59, 59, 59, 1);
    }

    &.left {
      background-color: rgba(175, 23, 19, 1);
    }
  }

  .text {
    align-items: center;
    flex-direction: column;

    &.right {
      text-align: right;
      align-self: center;
      justify-self: center;
    }
  }

  .text-container {
    align-content: center;
    margin: 5%;
    margin-right: 5%;
    color: rgb(255, 255, 255);
  }

  .image-container {
    display: flex;
    object-fit: contain;

    .image {
      max-width: 400px;
      max-height: 400px;
      border: none;
    }
  }
}

@media (max-width: 768px) {
  .team-member-container {
    gap: 0.75rem;
  }

  .team-member-container .name {
    font-size: 1.25rem;
  }

  .team-member-container .discipline,
  .team-member-container .component {
    font-size: 0.75rem;
  }

  .team-member-container .image-text-component {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .team-member-container .image-container .image {
    max-width: 100%;
    max-height: 250px;
  }
}
