.status_bar {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 0.5rem 3rem 1.5rem;

  color: black;

  border-style: solid;
  border-width: 2px;
  border-radius: 1rem;

  .title {
    display: inline;
    text-align: center;
    vertical-align: top;
  }

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      font-size: 1.5rem;
    }

    p {
      padding-left: 1rem;
      padding-top: 1rem;
    }

    .dot {
      height: 20px;
      width: 20px;
      background-color: #bbb;
      border-radius: 50%;
    }
  }
}

@media (max-width: 768px) {
  .status_bar {
    display: flex;
    flex-direction: column;
    margin: 2rem;

    .status_content {
      display: flex;
      flex-direction: row;
    }

    margin-bottom: 1.5rem;
    padding: 0.5rem;

    .info {
      display: flex;
      flex-direction: column;

      .icon {
        font-size: 1.5rem;
      }

      p {
        text-align: center;
        padding: 0.5rem;
      }

      .dot {
        height: 20px;
        width: 20px;
        background-color: #bbb;
        border-radius: 50%;
      }
    }
  }

  @media (max-height: 700px) {
    .status_bar {
      display: flex;
      flex-direction: column;
      margin: 2rem;

      .status_content {
        display: flex;
        flex-direction: row;
      }

      margin-bottom: 1.5rem;
      padding: 0.5rem;

      .info {
        display: flex;
        flex-direction: column;

        .icon {
          font-size: 1rem;
        }

        p {
          text-align: center;
          padding: 0.25rem;
        }

        .dot {
          height: 20px;
          width: 20px;
          background-color: #bbb;
          border-radius: 50%;
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .status_bar {
    display: flex;
    flex-direction: column;
    margin: 2rem;

    .status_content {
      display: flex;
      flex-direction: row;
    }

    margin-bottom: 1.5rem;
    padding: 0.5rem;

    .info {
      display: flex;
      flex-direction: column;

      .icon {
        font-size: 1rem;
      }

      p {
        text-align: center;
        padding: 0.25rem;
        font-size: 0.75rem;
      }

      .dot {
        height: 20px;
        width: 20px;
        background-color: #bbb;
        border-radius: 50%;
      }
    }
  }
}
