/* Spinner Animation Credit: https://codepen.io/supah/pen/BjYLdW */

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 100px;
  height: 100px;
}

.spinner .path {
  stroke: #af1713;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@media (max-width: 768px) {
  .spinner {
    width: 75px;
    height: 75px;
  }
}
