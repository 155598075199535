.title-card {
  background-color: rgba(175, 23, 19, 1);
  width: fit-content;
  height: fit-content;
  padding: 16px;
  padding-left: 5%;
  padding-right: 5%;
  color: rgb(230, 230, 230);
  font-size: 30px;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .title-card {
    font-size: 20px;
  }
}
