.student-details-container {
  width: 400px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;

  .left-container {
    padding: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
  }

  .right-container {
    width: 240px;
    font-size: 12px;
    font-weight: 400;
  }

  .info-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: flex;
  }

  .name {
    width: 160px;
    font-size: 16px;
    font-weight: 400;
  }

  .discipline {
    align-self: stretch;
    font-size: 12px;
    font-weight: 400;
  }

  .component {
    font-size: 10px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .student-details-container {
    gap: 0;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;

    .left-container {
      align-items: center;
      padding: 12px;
    }

    .right-container {
      font-size: 12px;
      text-align: center;
      align-items: center;
    }

    .info-container {
      gap: 8px;
    }

    .name {
      font-size: 15px;
      text-align: center;
      align-items: center;
    }

    .discipline {
      align-self: center;
    }
    .component {
      font-size: 10px;
    }
  }
}
