.quad_number__icon {
  font-size: 1rem;
}
.quad_number__text-label {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.quad_number__text-value {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}
.quad_number__text-value > span {
  font-size: 1rem;
  margin-left: 0.3rem;
}
.quad_number__container {
  text-align: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
}
.quad_number_value_colour {
  border-radius: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.quad_parameter {
  display: flex;
  justify-content: space-evenly;
}
