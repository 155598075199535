body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Roboto Condensed";
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: Roboto, sans-serif;
}

a:visited {
  color: white;
  text-decoration: none;
  font-family: Roboto, sans-serif;
}

h1 {
  font-size: 60px;
  max-width: 700px;
  padding-top: 2rem;
}

.status_bar h3 {
  font-size: 1.5rem;
  padding-top: 0.5rem;
}

.link {
  font-family: Roboto, sans-serif;
  color: white;

  &.fsae {
    color: black;
  }
}

.link:hover {
  color: #af1713;

  &.footer {
    color: black;
  }
  font-family: Roboto, sans-serif;
}

/* Background stylings */
.background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("images/backgrounds/wesmoBackGround.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  width: 100%;
  height: 100%;
  color: white;

  &.engineering {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("images/car_backend_cropped.jpg");
  }

  &.contact {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("images/backgrounds/pushing_car.jpg");
  }

  &.fsae {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("images/backgrounds/cars.jpg");
  }

  &.about {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("images/backgrounds/wesmo_night.jpeg");
  }

  &.history-2023 {
    background-image: none;
    background-color: rgba(59, 59, 59, 1);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &.history-table {
    background-image: none;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &.history {
    background-image: none;
    background-color: rgb(167, 165, 165);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &.data {
    background-image: none;
    background-color: rgb(255, 255, 255);
    overflow-y: scroll;
    overflow-x: hidden;

    &.load {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)),
        url("images/backgrounds/cars.jpg");
    }
  }

  &.sponsors {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("images/backgrounds/car_motion_tall.png");
    background-attachment: local;
  }
}

/* Contact Us Page */
.socials {
  &.icon {
    font-size: 60px !important;
    margin-right: 5rem;
  }

  &.container {
    display: flex;
    align-items: center;
    padding-left: 3rem;

    &.top {
      padding-top: 3rem;
    }

    &.middle {
      padding: 3rem;
    }

    &.bottom {
      padding-bottom: 3rem;
    }
  }
}

.contact-info {
  background-color: rgba(59, 59, 59, 1);
  width: 650px;
  height: fit-content;
  margin: 40px;
  left: 70px;
  font-size: 20px;
  justify-content: center;
  vertical-align: middle;
  padding: 2rem;
}

.contact-text {
  color: white;
  font-family: "Roboto";
  font-size: 20px;
  justify-content: center;
  text-align: left;
  vertical-align: middle;
  font-family: "Roboto Condensed";
}

/* Nav bar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 4rem;
  color: white;

  .nav-left {
    align-items: center;
    width: 90%;
  }

  .nav-right {
    z-index: 2000;
    position: relative;
  }
}

.footer {
  margin: 0;
  width: 100%;
  padding-bottom: 1rem;
  background-color: #af1713;
}

.footer .content-container {
  display: flex;
  justify-content: center;
}

.footer .content {
  margin-left: 4rem;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.footer .content > div {
  margin: 0.5rem;
  flex: 1;
  min-width: 200px;
}

@media (max-width: 768px) {
  .footer .content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer .content > div {
    width: 100%;
  }
}

.navbar a {
  color: rgb(209, 207, 207);
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: 100;
  font-size: medium;
  letter-spacing: 1px;
}

.navbar a:hover {
  color: white;
}

/* Tags */
#team {
  margin-bottom: 3rem;
}

#scroll {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

#mainTitle {
  padding: 70px;
  padding-top: 3rem;
  font-family: "Roboto Condensed";
}

#year {
  background-color: #af1713;
  width: 90px;
  text-align: center;
  padding: 10px;
  color: white;
  font-weight: 100;
  font-family: "Roboto Condensed";
}

#subTitle {
  font-size: 25px;
  font-weight: 100;
  margin-bottom: 200px;
  cursor: pointer;
  border-bottom: 1px solid white;
  width: fit-content;
  font-family: "Roboto Condensed";
}

#subTitle:hover {
  color: #af1713;
}

#wesmo {
  color: #af1713;
  font-style: italic;
}

/* Image Text Component */
.image-text-component {
  display: flex;
  width: 80%;
  height: fit-content;
  font-family: Roboto, sans-serif;

  &.about {
    background-color: rgba(59, 59, 59, 1);
    width: calc(50% + 100px);
    right: 0;
    margin-right: 50%;
    padding-right: 10%;
    margin: 0 auto;
    position: absolute;
    padding: 20px;
    min-height: 150px;
    font-family: "Roboto Condensed";
  }

  &.left {
    background-color: rgba(217, 217, 217, 1);
    height: fit-content;
    color: rgb(0, 0, 0);

    &.daq {
      background-color: rgba(175, 23, 19, 1);
      width: 60%;
      border: 10%;
      align-items: center;
    }

    &.fsae {
      background-color: rgb(166, 158, 158);
      width: 40%;
      border: 10%;
    }
  }

  &.right {
    background-color: rgba(175, 23, 19, 1);
    margin-left: 20%;

    &.daq {
      background-color: rgba(217, 217, 217, 1);
      align-items: center;
    }

    &.fsae {
      background-color: rgba(59, 59, 59, 1);
      width: calc(50% + 100px);
      float: right;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    font-family: "Roboto Condensed";

    &.alt {
      align-items: left;
      margin: 5%;

      &.about {
        color: rgb(255, 255, 255);
      }
    }

    &.fsae-left {
      margin: 2rem;

      .pdf {
        background-color: rgba(59, 59, 59, 1);
        color: white;
        text-align: center;
        font-family: Roboto, sans-serif;
      }

      p {
        text-align: left;
        align-items: center;
        font-size: smaller;
        color: black;
      }
    }

    &.daq-right {
      margin: 2%;
      margin-left: 3%;
      margin-right: 5%;

      p {
        text-align: left;
        align-items: center;
        font-size: smaller;
        color: black;
      }
    }

    &.daq-left {
      margin: 2%;
      margin-left: 5%;
      min-width: 35%;
      margin-right: 5%;

      &.column {
        min-width: 20%;
      }

      p {
        text-align: left;
        align-items: center;
        font-size: medium;
        color: white;
      }
    }

    &.fsae-right {
      display: flex;
      margin: 5%;
      margin-right: 5%;

      p {
        text-align: left;
        align-items: center;
        font-size: smaller;
        color: white;
      }
    }

    &.left {
      flex: 1;
      justify-content: center;
      align-items: center;
      color: black;

      p {
        margin: 5%;
        text-align: left;
        font-size: smaller;
        color: black;
        flex-direction: column;
      }
    }

    &.right {
      align-items: left;
      margin: 5%;
      color: white;

      p {
        margin: 0;
        text-align: left;
        font-size: small;
        flex-direction: column;
        color: white;
      }
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    &.fsae {
      max-height: 100%;
    }

    &.daq {
      object-fit: cover;
      height: max-content;
    }

    &.left {
      flex: 1;
    }

    .image {
      max-width: 100%;
      object-fit: cover;

      &.fsae {
        height: 100%;
        &.logo {
          border: 10%;
          padding-left: 10%;
          object-fit: contain;
        }
      }

      &.left {
        height: max-content;
      }
      &.right {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.left-display {
  display: block;
  margin-bottom: 2rem;
  font-family: "Roboto Condensed";
}

.right-display {
  display: flex;
  float: right;
  display: block;
  align-content: flex-end;
  margin-bottom: 2rem;
  margin-top: 4rem;
  font-family: "Roboto Condensed";

  &.about {
    margin-top: 10rem;
    margin-bottom: 3rem;
  }
}

.sponsors {
  .title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 5rem;
    color: black;
    font-family: "Roboto Condensed";
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.platinum {
      background-color: #e5e4e2;
    }
    &.gold {
      background-color: #ffd700;
    }
    &.silver {
      background-color: #c0c0c0;
    }
    &.bronze {
      background-color: #b08d57;
    }
  }

  .info {
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-family: "Roboto Condensed";

    .imagetext-component {
      display: flex;
      background-color: rgba(217, 217, 217, 1);
      width: 30%;
      min-width: 330px;
      height: fit-content;

      .text-container {
        display: flex;
        flex-direction: column;
        margin-left: 3rem;
        vertical-align: middle;
        margin-top: 2rem;

        p {
          text-align: left;
          font-size: large;
          color: black;
          vertical-align: middle;
        }

        .more {
          background-color: #af1713;
          color: white;
          padding: 0.5rem;
          text-align: center;
        }
        .more:hover {
          color: black;
        }
      }

      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 100%;
      }

      .image {
        width: 100%;
        height: 100%;
        max-height: 150px;
        object-fit: contain;
      }
    }
  }
}

/* Explore Car Page */
.info-explore-container {
  position: relative;
}

.helper {
  display: none;
}
.explore {
  margin-left: 20%;
  margin-top: 20%;
}

/* History Main Page */
.year-title {
  background-color: rgba(175, 23, 19, 1);
  width: fit-content;
  height: fit-content;
  padding: 16px;
  padding-left: 5%;
  padding-right: 5%;
  color: white;
  font-size: 25px;
  font-family: "Roboto Condensed";
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
}

.subtitle {
  background-color: #3b3b3b;
  width: max-content;
  height: fit-content;
  padding: 16px;
  color: white;
  font-family: "Roboto Condensed";
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;

  .year {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }

  .more {
    color: white;
    cursor: pointer;
    font-size: 13px;
    padding: 13px;
    padding: 1rem;
  }
  .more:hover {
    color: #af1713;
  }
}

.history-car {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  object-fit: cover;
  padding: 2rem;
}

#car_table {
  border: 1px solid #ddd;
  margin-bottom: 2rem;
  color: black;
  box-sizing: border-box;
  table-layout: auto;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-family: "Roboto Condensed";
}

#table_col {
  background-color: #d4d4d4;
  color: black;
  border-top: 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-family: "Roboto Condensed";
}

/* DASHBOARD COMPONENTS CSS */
.dashboard {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;

  .track-time {
    color: black;
    position: absolute;
    font-size: large;
    top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.dashboard-row {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  padding-bottom: 0.75rem;
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  h2 {
    font-size: 40px;
    color: white;
  }
}

.graph-box {
  width: 800px;
  height: 350px;
  display: flex;
  justify-content: space-between;

  .graph-container {
    display: flex;
    width: 90%;
    height: 90%;
    flex-direction: row;
  }
}

.no-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  padding-top: 8rem;
  color: black;

  h2 {
    font-size: 40px;
    color: white;
  }

  h4 {
    color: white;
  }
}

ol {
  padding-left: 1.5rem;
  list-style-type: none;
}

.pop_up {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  z-index: 1;
}

.pop_up_container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  height: 400px;
  background-color: #adadad;
  border-radius: 1rem;
  padding: 16px;
  box-sizing: border-box;
  z-index: 1000;
}

.close-button {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 0;
  font-size: 28px;
  line-height: 28px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

.close-button:hover {
  cursor: pointer;
  background: none;
  color: #af1713;
}

.info-popup {
  color: black;
  padding: 1rem;

  h3 {
    font-family: "Roboto Condensed";
  }

  td {
    text-align: left;
    vertical-align: middle;
    padding-right: 1.5rem;
  }

  th {
    padding-top: 1rem;
  }

  .dot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-left: 1rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 32px;
    padding-top: 1rem;
  }

  .navbar {
    &.nav-explore {
      flex-wrap: nowrap;
      display: flex;
      position: sticky;
      top: 0;
      z-index: 2000;

      .nav-right {
        z-index: 2000;
        right: 0;
        position: fixed;
        padding-right: 1.5rem;
      }

      .nav-left {
        width: 90%;
        top: 0;
        left: 0;
      }
    }

    padding: 0.25rem 1.5rem 0.5rem;
    justify-content: space-between;

    .nav-left {
      &.nav-dashboard {
        width: 70%;
        align-items: self-start;
        padding-top: 0;
        margin: 0;
      }
    }
  }

  #imgLogo {
    width: 150px;
  }

  #mainTitle {
    padding: 30px;
  }

  .status_bar h3 {
    font-size: 1rem;
    padding-top: 0.2rem;
  }

  .background {
    background-size: cover;
    overflow-y: scroll;
  }

  .info-explore-container {
    position: relative;
  }

  .background-scrollable {
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    background-attachment: local;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }

  #info-drawer {
    overflow-y: scroll;
    height: 50vh;
    max-height: 50vh;
  }

  .explore-body {
    margin: 0;
    overflow: hidden;
    width: 300%;
    overflow-x: hidden;

    .helper {
      display: block;
      font-size: large;
      margin-left: 3.5rem;
      margin-top: 1rem;
    }
  }

  /* Contact Us Page */
  .socials {
    &.icon {
      font-size: 40px !important;
      margin-right: 1rem;
    }

    &.container {
      display: flex;
      align-items: center;
      padding-left: 0rem;

      &.top {
        padding-top: 2rem;
      }

      &.middle {
        padding: 0rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      &.bottom {
        padding-bottom: 2rem;
      }
    }
  }

  .contact-info {
    width: 275px;
    height: fit-content;
    margin: 10px;
    margin-top: 40px;
    font-size: 1rem;
    justify-content: center;
    vertical-align: middle;
    padding: 2rem;
  }

  .contact-text {
    color: white;
    font-family: "Roboto";
    font-size: 15px;
    justify-content: center;
    text-align: left;
    vertical-align: middle;
    font-family: "Roboto Condensed";
  }

  .link {
    font-family: Roboto, sans-serif;
    color: white;
    text-decoration: underline;

    &.fsae {
      color: white;
    }
  }

  /* Image Text Component */
  .image-text-component {
    display: flex;
    width: 80%;
    height: fit-content;

    &.about {
      flex-direction: column;
      width: calc(50% + 100px);
      margin-right: 50%;
      padding-right: 10%;
      padding: 20px;
      padding-bottom: 1rem;
      margin: 0;
      margin-bottom: 5rem;
      position: absolute;
      min-height: 150px;
    }

    &.left {
      flex-direction: column;

      &.daq {
        background-color: rgba(175, 23, 19, 1);
        width: 60%;
        border: 10%;
        align-items: center;
      }

      &.fsae {
        width: 60%;
        border: 0rem;
        padding-right: 1rem;
      }
    }

    &.right {
      flex-direction: column;

      &.fsae {
        flex-direction: column;
        width: calc(50% + 100px);
        float: right;
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;

      &.fsae {
        max-height: 50%;
        max-width: 100%;
        align-content: center;
      }

      &.daq {
        object-fit: cover;
        height: max-content;
      }

      &.left {
        object-fit: cover;
        justify-content: left;
        max-width: 100%;
        /* min-width: 645px; */
      }

      .image {
        max-width: 50%;
        object-fit: cover;

        &.fsae {
          height: 100%;

          &.logo {
            padding-left: 10%;
            object-fit: cover;
            max-height: fit-content;
            max-width: min-content;
            min-width: 100px;
          }
        }

        &.left {
          height: 100%;
          max-width: inherit;
          object-fit: contain;
        }
        &.right {
          object-fit: contain;
          min-width: 285px;
          max-width: inherit;
        }
      }
    }
  }

  /* Explore Car Page */

  .explore {
    margin-left: 5%;
    margin-top: 5%;
  }

  /* Sponsors Page */
  .sponsors {
    .title {
      margin-top: 1rem;
      margin-bottom: 1rem;
      height: 3rem;
      font-size: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info {
      margin-top: 4rem;
      margin-bottom: 4rem;

      .imagetext-component {
        display: flex;
        width: 30%;
        min-width: 330px;
        height: fit-content;

        .text-container {
          display: flex;
          flex-direction: column;
          margin-left: 3rem;
          vertical-align: middle;
          margin-top: 2rem;

          p {
            text-align: left;
            font-size: large;
            color: black;
            vertical-align: middle;
          }

          .more {
            padding: 0.5rem;
          }
        }

        .image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 100%;
        }

        .image {
          max-width: 75%;
          height: auto;
          width: auto;
          max-height: 100px;
          object-fit: contain;
        }
      }
    }
  }

  /* History Main Page */
  .year-title {
    padding: 10px;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 15px;
    margin-left: 1rem;
  }

  .subtitle {
    padding: 10px;
    margin-left: 1rem;

    .year {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 15px;
    }

    .more {
      font-size: 13px;
      padding: 10px;
      padding: 1rem;
    }
  }

  .history-car {
    width: 80%;
  }

  /* Dashboard Page */
  .loading {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    h2 {
      font-size: 20px;
      color: white;
    }
  }

  .no-data {
    font-size: 1rem;
    padding-top: 4rem;

    h2 {
      font-size: 30px;
    }
  }

  .graph-box {
    width: 80vw;
    height: 50vh;

    h3 {
      font-size: medium;
    }

    .graph-container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;

      .quad-graph {
        max-height: 60%;
      }
    }
  }

  /* Pop Up Containers */

  .pop_up_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    height: 60vh;
    overflow-y: scroll;
  }

  .close-button {
    position: fixed;
    top: 10px;
    right: 15px;
  }

  .info-popup {
    padding: 1rem;

    td {
      padding-right: 1.5rem;
    }

    th {
      padding-top: 1rem;
    }

    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-left: 1rem;
    }
  }

  .dashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .track-time {
      color: black;
      position: absolute;
      font-size: large;
      top: 100px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dashboard-row {
    display: flex;
    flex-direction: column;
    padding-top: 0.75rem;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 24px;
    padding-top: 0.5rem;
  }
}
