.bar_text-label {
  font-size: 1rem;
  color: #ffffff;
}
.bar_text-value {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: white;
}
.bar_parent {
  text-align: center;
  height: 100%;
  width: 100%;
}
.bar_container {
  box-sizing: content-box;
  position: relative;
  display: flex;
  overflow: hidden;
  height: 1.75rem;
  border-radius: 1rem;
  background-color: white;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.bar_container > span {
  display: block;
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 1rem;
}
.bar_text-value > span {
  font-size: 1rem;
  margin-left: 0.3rem;
}
